import { Amplify, Auth } from 'aws-amplify'

interface Modules {
  amplify: typeof Auth
}

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()

  Amplify.configure({
    aws_project_region: config.public.awsRegion,
    aws_cognito_region: config.public.awsRegion,
    aws_user_pools_id: config.public.amplifyPool,
    aws_user_pools_web_client_id: config.public.amplifyClientId,
    oauth: {},
    aws_cognito_username_attributes: [],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: ['EMAIL'],
    aws_cognito_mfa_configuration: 'OFF',
    aws_cognito_mfa_types: [],
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: [
        'REQUIRES_LOWERCASE',
        'REQUIRES_UPPERCASE',
        'REQUIRES_NUMBERS',
        'REQUIRES_SYMBOLS',
      ],
    },
    aws_cognito_verification_mechanisms: [],
  })

  const modules: Modules = {
    amplify: Auth,
  }

  return {
    name: 'AuthPlugin',
    parallel: true,
    provide: {
      auth: modules,
    },
  }
})

import { DateTime } from 'luxon'
import { Auth } from 'aws-amplify'
import { toCanvas } from 'qrcode'
import type { AxiosResponse } from 'axios'

export default defineNuxtPlugin(() => {
  const formatPhoneNumber = (phoneNumberString: string) => {
    const cleaned: string = phoneNumberString.replace(/\D/g, '')
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      const intlCode = match[1] ? '+1 ' : ''
      const response = [
        intlCode,
        '(',
        match[2],
        ') ',
        match[3],
        '-',
        match[4],
      ].join('')
      return response
    }
    return null
  }

  const formatNumberWithCommas = (
    value: number | string | null | undefined,
  ) => {
    if (!value) {
      return '0'
    }

    if (typeof value === 'number') {
      value = value.toString()
    }

    if (value === '0') {
      return value
    }

    return value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
  }

  // skipcq: JS-0323
  function replaceWithDefault(e: any) {
    e.target.src = '/images/ProductImage.svg'
  }

  function slugify(str: string) {
    return String(str)
      .normalize('NFKD') // split accented characters into their base characters and diacritical marks
      .replace(/[\u0300-\u036F]/gu, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
      .trim() // trim leading or trailing whitespace
      .toLowerCase() // convert to lowercase
      .replace(/[^a-z0-9 -]/gu, '') // remove non-alphanumeric characters
      .replace(/\s+/gu, '_') // replace spaces with hyphens
      .replace(/_+/gu, '_') // remove consecutive hyphens
  }

  const formatDate = (
    value: string,
    format: Intl.DateTimeFormatOptions = DateTime.DATETIME_SHORT,
    defaultOutput = '',
  ) => {
    const str = DateTime.fromISO(value, { zone: 'UTC' })
      .setZone('system')
      .toLocaleString(format)
    if (str === 'Invalid DateTime') {
      return defaultOutput
    }
    return str
  }

  const addSpacesToCamelCase = (str: string): string => {
    return str.replace(/([a-z])([A-Z])/g, '$1 $2')
  }

  const checkForValid = () => {
    const formInvalid = document.querySelectorAll('.form-required')
    let error = 0
    if (formInvalid.length) {
      for (const el of formInvalid) {
        if (!(<HTMLInputElement>el).value) {
          ;(<HTMLInputElement>el).classList.add('border-red-600')
          error += 1
        } else {
          ;(<HTMLInputElement>el).classList.remove('border-red-600')
        }
      }
      if (error > 0) {
        return false
      }
    }

    return true
  }

  const checkForValidWithResponse = () => {
    const formInvalid = document.querySelectorAll('.form-required')
    const fieldList: string[] = []

    if (formInvalid.length) {
      for (const el of formInvalid) {
        if (!(<HTMLInputElement>el).value) {
          ;(<HTMLInputElement>el).classList.add('border-red-600')
          fieldList.push((<HTMLInputElement>el).name.replace('_', ' '))
        } else {
          ;(<HTMLInputElement>el).classList.remove('border-red-600')
        }
      }
    }

    return fieldList.join(', ')
  }

  const setPreferredMFA = async (mfa: string): Promise<boolean> => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      let value = true
      switch (mfa) {
        case 'TOTP':
          await Auth.setPreferredMFA(user, 'TOTP')
          break
        case 'SMS':
          await Auth.setPreferredMFA(user, 'SMS')
          break
        case 'NOMFA':
          await Auth.setPreferredMFA(user, 'NOMFA')
          break
        default:
          value = false
          break
      }
      return value
    } catch (error) {
      return false
    }
  }

  const setupTotp = async () => {
    const user = await Auth.currentAuthenticatedUser()
    const code = await Auth.setupTOTP(user)
    return code
  }

  const verifyTotp = async (totpCode: string | number): Promise<boolean> => {
    try {
      const user = await Auth.currentAuthenticatedUser()

      await Auth.verifyTotpToken(user, totpCode.toString())
      setPreferredMFA('TOTP')
      return true
    } catch {
      return false
    }
  }

  const removeTotp = async () => {
    await setPreferredMFA('NOMFA')
  }

  const qrCode = async (data: string): Promise<HTMLElement> => {
    return await toCanvas(data)
  }

  const humanReadableDataSize = (bytes: number, dp = 0) => {
    const thresh = 1024

    if (Math.abs(bytes) < thresh) {
      return `${bytes} B`
    }

    const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    let unit = -1
    const range = 10 ** dp

    do {
      bytes /= thresh
      ++unit
    } while (
      Math.round(Math.abs(bytes) * range) / range >= thresh &&
      unit < units.length - 1
    )

    return `${bytes.toFixed(dp)} ${units[unit]}`
  }

  interface Dimensions {
    Width: number
    Height: number
  }

  const getDimensions: (file: File) => Promise<Dimensions> = (file: File) => {
    return new Promise<Dimensions>((resolve) => {
      const { $log } = useNuxtApp()
      $log.debug('HERE3')
      const fr = new FileReader()
      fr.onload = () => {
        const img = new Image()
        img.onload = () => {
          $log.debug('HERE4')
          resolve({
            Width: img.width,
            Height: img.height,
          })
        }
        img.src = fr.result as string
      }
      fr.readAsDataURL(file)
    })
  }

  const downloadBinaryFile = (
    // skipcq: JS-0323
    response: AxiosResponse<File, any>,
    fileName: string | undefined | null = undefined,
  ) => {
    const contentType = response.headers['content-type']
    const blob = new Blob([response.data], { type: contentType })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    const file =
      fileName ??
      response.headers['content-disposition']
        .split(';')
        .find((n) => n.includes('filename='))
        .replace('filename=', '')
        .trim()
    link.href = url
    link.setAttribute('download', file)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }
  const downloadFile = (
    // skipcq: JS-0323
    response: AxiosResponse<string, any>,
    fileName: string,
  ) => {
    const contentType = response.headers['content-type']
    const blob = new Blob([response.data], { type: contentType })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')

    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }
  // skipcq: JS-0323
  const formatCurrency = (value: any) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 4,
    })

    return formatter.format(value)
  }
  // skipcq: JS-0323
  const formatPreciseCurrency = (value: any, precision: number) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: precision,
    })

    return formatter.format(value)
  }
  // skipcq: JS-0323
  const formatZipCode = (value: any) => {
    const zipCodeRegex = /^\d{5}(-\d{4})?$/
    return Boolean(zipCodeRegex.test(value))
  }
  const awsStringValidator = (value: string) => {
    const reg = /^[a-zA-Z0-9!_.*'() -]+$/
    return reg.test(value)
  }

  return {
    name: 'SharedFunctions',
    parallel: true,
    provide: {
      formatPhoneNumber,
      formatNumberWithCommas,
      formatDate,
      replaceWithDefault,
      slugify,
      addSpacesToCamelCase,
      checkForValid,
      checkForValidWithResponse,
      setPreferredMFA,
      setupTotp,
      verifyTotp,
      removeTotp,
      qrCode,
      humanReadableDataSize,
      getDimensions,
      downloadFile,
      downloadBinaryFile,
      formatCurrency,
      formatPreciseCurrency,
      formatZipCode,
      awsStringValidator,
    },
  }
})

import Bugsnag from '@bugsnag/js'
import { useNotificationStore } from '~/stores/pushNotifications'

export default defineNuxtRouteMiddleware(async () => {
  // skips middleware on server side -- only helps local dev
  if (process.server) return

  const { $auth, $WSConnect, $log } = useNuxtApp()
  const pushStore = useNotificationStore()
  try {
    Bugsnag.leaveBreadcrumb('Websocket middleware getting authenticated user')
    const user = await $auth.amplify.currentAuthenticatedUser()
    if (user.confirmed === false) {
      return
    }
    if (!pushStore.isSocketConnected) {
      pushStore.setAutoReconnect(true)
      $WSConnect()
    }
  } catch (e) {
    $log.info('Exception in socket middleware')
    Bugsnag.notify(e)
  }
})

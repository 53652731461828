import { useSessionStorage, StorageSerializers } from '@vueuse/core'
import { type CompanyResponse } from '../models/apiCore'

export const useCompanyStore = defineStore('companies', () => {
  const loading: Ref<boolean> = ref(false)
  const currentCompany: Ref<CompanyResponse | null> = useSessionStorage(
    'currentCompany',
    null,
    { serializer: StorageSerializers.object },
  )
  const clientViewCompany: Ref<CompanyResponse | null> = useSessionStorage(
    'clientViewCompany',
    null,
    { serializer: StorageSerializers.object },
  )
  const companiesList: Ref<CompanyResponse[] | null> = ref(null)

  const getCurrentCompany = () => currentCompany.value
  const loadCompanies = async () => {
    try {
      loading.value = true
      const { $api } = useNuxtApp()
      const response = await $api.core.api.getCompanies()
      companiesList.value = response.data
      if (currentCompany.value === null) {
        currentCompany.value = companiesList.value[0]
      }
    } catch (e) {
      const { $addToastError } = useNuxtApp()
      $addToastError('Error', 'Error fetching company list')
    } finally {
      loading.value = false
    }
  }

  const setCurrentCompany = (company: CompanyResponse) => {
    currentCompany.value = company
  }
  const assumeClient = async (id: number) => {
    if (companiesList.value) {
      const company = companiesList.value.find((item: CompanyResponse) => {
        return item.Id === id
      })

      clientViewCompany.value = company as CompanyResponse
      currentCompany.value = company as CompanyResponse
      await navigateTo(`/company/${id}`)
    }
  }
  const purge = () => {
    currentCompany.value = null
    clientViewCompany.value = null
    companiesList.value = null
  }

  return {
    companiesList,
    currentCompany,
    clientViewCompany,
    loadCompanies,
    setCurrentCompany,
    purge,
    assumeClient,
    getCurrentCompany,
  }
})

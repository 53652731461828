import { defineNuxtPlugin } from '#app/nuxt'
import { LazyIcon, LazyIconCSS, LazyPrimeCalendar, LazyPrimeDropdown, LazyPrimeInputMask, LazyPrimeMultiSelect, LazyPrimePassword, LazyPrimeSelectButton, LazyPrimeColumn, LazyPrimeDataTable, LazyPrimeFileUpload, LazyPrimeContextMenu, LazyPrimeSteps, LazyPrimeToast, LazyPrimeProgressBar } from '#components'
const lazyGlobalComponents = [
  ["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
["PrimeCalendar", LazyPrimeCalendar],
["PrimeDropdown", LazyPrimeDropdown],
["PrimeInputMask", LazyPrimeInputMask],
["PrimeMultiSelect", LazyPrimeMultiSelect],
["PrimePassword", LazyPrimePassword],
["PrimeSelectButton", LazyPrimeSelectButton],
["PrimeColumn", LazyPrimeColumn],
["PrimeDataTable", LazyPrimeDataTable],
["PrimeFileUpload", LazyPrimeFileUpload],
["PrimeContextMenu", LazyPrimeContextMenu],
["PrimeSteps", LazyPrimeSteps],
["PrimeToast", LazyPrimeToast],
["PrimeProgressBar", LazyPrimeProgressBar],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})

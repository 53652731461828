import { Hub } from 'aws-amplify'
import axios from 'axios'

export default defineNuxtPlugin(({ $config }) => {
  const { $log, $updateToken } = useNuxtApp()
  // skipcq: JS-R1005, JS-0323
  const listener = (data: any) => {
    if ($config.public.environment !== 'production') {
      $log.debug('data', data)
    }

    switch (data?.payload?.event) {
      case 'configured':
        $log.debug('the Auth module is configured')
        break
      case 'signIn': {
        $updateToken(data?.payload?.data?.signInUserSession?.idToken?.jwtToken)
        axios
          .post(
            `${$config.public.assetBaseUrl}authenticate/token`,
            {
              jwt: data?.payload?.data?.signInUserSession?.idToken?.jwtToken,
            },
            { withCredentials: true },
          )
          .then((result) => {
            if (result.status !== 200) {
              throw new Error(result.data)
            }
          })

        break
      }
      case 'signIn_failure':
        processStep(data?.payload?.data)
        $log.error('user sign in failed')
        break
      case 'signUp':
        $log.info('user signed up')
        break
      case 'signUp_failure':
        $log.error('user sign up failed')
        break
      case 'confirmSignUp':
        $log.info('user confirmation successful')
        break
      case 'completeNewPassword_failure':
        $log.error('user did not complete new password flow')
        break
      case 'autoSignIn':
        $log.info('auto sign in successful')
        break
      case 'autoSignIn_failure':
        $log.error('auto sign in failed')
        break
      case 'forgotPassword':
        $log.info('password recovery initiated')
        break
      case 'forgotPassword_failure':
        $log.error('password recovery failed')
        break
      case 'forgotPasswordSubmit':
        $log.info('password confirmation successful')
        break
      case 'forgotPasswordSubmit_failure':
        $log.error('password confirmation failed')
        break
      case 'verify':
        $log.info('TOTP token verification successful')
        break
      case 'tokenRefresh':
        // No token is returned, must handle update manually
        // $log.info('token refresh succeeded')
        break
      case 'tokenRefresh_failure':
        $log.error('token refresh failed')
        break
      case 'cognitoHostedUI':
        $log.info('Cognito Hosted UI sign in successful')
        break
      case 'cognitoHostedUI_failure':
        $log.error('Cognito Hosted UI sign in failed')
        break
      case 'customOAuthState':
        $log.info('custom state returned from CognitoHosted UI')
        break
      case 'customState_failure':
        $log.error('custom state failure')
        break
      case 'parsingCallbackUrl':
        $log.info('Cognito Hosted UI OAuth url parsing initiated')
        break
      case 'userDeleted':
        $log.info('user deletion successful')
        break
      case 'updateUserAttributes':
        $log.info('user attributes update successful')
        break
      case 'updateUserAttributes_failure':
        $log.info('user attributes update failed')
        break
      case 'signOut':
        // don't do anything here manually -- changing passwords also signs you out
        break
      default:
        $log.info('unknown event type')
        break
    }
  }

  Hub.listen('auth', listener)

  // skipcq: JS-0323
  function processStep(step: any) {
    // skipcq: JS-0323
    const err = JSON.parse(JSON.stringify(step as unknown as any))
    if (err?.code) {
      step = err.code
    }

    switch (step) {
      case 'NEW_PASSWORD_REQUIRED':
        navigateTo('/auth/new-password')
        break
      case 'UserNotConfirmedException':
        navigateTo('/auth/confirm')
        break
      case 'PasswordResetRequiredException':
        navigateTo('/auth/reset-password')
        break
      default:
        break
    }
  }
})

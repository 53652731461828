import { useNotificationStore } from '~/stores/pushNotifications'
import type { PushNotification } from '@/models/pushNotification'
export default defineNuxtPlugin(() => {
  const pushStore = useNotificationStore()

  const config = useRuntimeConfig()
  let connection: WebSocket

  const { $auth, $log } = useNuxtApp()

  const Disconnect = () => {
    $log.error('Websocket Disconnected')
    try {
      connection.close()
    } catch (e) {
      $log.error('Exception when disconnecting. Error: ', e)
    }
  }
  const socketConnected = () => {
    $log.error('socket connected')
    pushStore.socketStateChanged(true)
  }
  const socketClosed = () => {
    pushStore.socketStateChanged(false)
    $log.error('Websocket closed')
    if (pushStore.isAutoReconnectEnabled) {
      setTimeout(async () => {
        // skipcq: JS-0357
        await Connect()
      }, 5000) // Wait 5 seconds before reconnecting
    }
  }
  const socketError = () => {
    pushStore.socketStateChanged(false)
    $log.error('Websocket closed')
    pushStore.setSocketError()
    if (pushStore.isAutoReconnectEnabled) {
      setTimeout(async () => {
        // skipcq: JS-0357
        await Connect()
      }, 5000) // Wait 5 seconds before reconnecting
    }
  }
  const socketMessage = async (ev: MessageEvent) => {
    try {
      const data = JSON.parse(ev.data)
      await pushStore.addNotification(data as PushNotification)
    } catch (e) {
      $log.error(`Error adding notification: ${e}`)
    }
  }
  const Connect = async () => {
    if (pushStore.isSocketConnected) return
    const userUpdate = await $auth.amplify.currentAuthenticatedUser()
    const token = userUpdate?.signInUserSession?.idToken?.jwtToken
    const serverAddress = config.public.websocketDomain
    let tokenEncoded = token
    if (tokenEncoded.substring(tokenEncoded.length - 2) === '==') {
      const val = tokenEncoded.substring(0, tokenEncoded.length - 2)
      tokenEncoded = `${val}--`
    }
    connection = new WebSocket(`wss://${serverAddress}`, ['P2', tokenEncoded])
    connection.onopen = socketConnected
    connection.onerror = socketError
    connection.onclose = socketClosed
    connection.onmessage = socketMessage
  }
  return {
    name: 'websocket',
    dependsOn: ['AuthPlugin'],
    provide: {
      WSConnect: Connect,
      WSDisconnect: Disconnect,
    },
  }
})

import { defineNuxtPlugin } from '#app'

// skipcq: JS-0323
export default defineNuxtPlugin((nuxtApp: any) => {
  const defaultLifeTime = 5000

  return {
    name: 'ToastyPlugin',
    parallel: true,
    provide: {
      // Enable the below line to allow toast to be used from any component, but it won't be styled.
      // toast: nuxtApp.vueApp.config.globalProperties.$toast,
      addToastSuccess: (title?: string, body?: string, lifeTime?: number) => {
        nuxtApp.vueApp.config.globalProperties.$toast.add({
          severity: 'success',
          summary: title,
          detail: body,
          styleClass:
            'p-2 bg-success text-success-content rounded-sm border-l-4 border-success-content mb-3',
          life:
            lifeTime && lifeTime > 0
              ? lifeTime
              : lifeTime && lifeTime <= 0
                ? undefined
                : defaultLifeTime,
        })
      },
      addToastError: (title?: string, body?: string, lifeTime?: number) => {
        nuxtApp.vueApp.config.globalProperties.$toast.add({
          severity: 'error',
          summary: title,
          detail: body,
          styleClass:
            'p-2 bg-error text-error-content rounded-sm border-l-4 border-error-content mb-3',
          life:
            lifeTime && lifeTime > 0
              ? lifeTime
              : lifeTime && lifeTime <= 0
                ? undefined
                : defaultLifeTime,
        })
      },
      addToastInfo: (title?: string, body?: string, lifeTime?: number) => {
        nuxtApp.vueApp.config.globalProperties.$toast.add({
          severity: 'info',
          summary: title,
          detail: body,
          styleClass:
            'p-2 bg-secondary text-secondary-content rounded-sm border-l-4 border-secondary-content mb-3',
          life:
            lifeTime && lifeTime > 0
              ? lifeTime
              : lifeTime && lifeTime <= 0
                ? undefined
                : defaultLifeTime,
        })
      },
      addToastWarning: (title?: string, body?: string, lifeTime?: number) => {
        nuxtApp.vueApp.config.globalProperties.$toast.add({
          severity: 'warning',
          summary: title,
          detail: body,
          styleClass:
            'p-2 bg-warning text-warning-content rounded-sm border-l-4 border-warning-content mb-3',
          life:
            lifeTime && lifeTime > 0
              ? lifeTime
              : lifeTime && lifeTime <= 0
                ? undefined
                : defaultLifeTime,
        })
      },
    },
  }
})

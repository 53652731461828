import { defineStore } from 'pinia'
import type {
  ToastPushNotification,
  PushNotification,
} from '@/models/pushNotification'

export const useNotificationStore = defineStore('notification', () => {
  const notifications: Ref<PushNotification[]> = ref([])
  const socketConnected: Ref<boolean> = ref(false)
  const autoReconnectEnabled: Ref<boolean> = ref(true)
  const errorCount: Ref<number> = ref(0)
  const {
    $auth,
    $addToastError,
    $addToastSuccess,
    $addToastInfo,
    $addToastWarning,
    $WSDisconnect,
    $WSConnect,
  } = useNuxtApp()
  // Direct Store access
  const userStore = useUserStore()
  const companiesStore = useCompanyStore()

  const addNotification = async (notification: PushNotification) => {
    if (notification.Module === 'user') {
      if (notification.ActionName === 'logout') {
        autoReconnectEnabled.value = false
        $WSDisconnect()
        userStore.purge()
        companiesStore.purge()
        await $auth.amplify.signOut()
        navigateTo('/login')
      }
      if (notification.ActionName === 'redirectExternal') {
        navigateTo(notification.Parameters as string, { external: true })
      }
      if (notification.ActionName === 'redirectInternal') {
        navigateTo(notification.Parameters as string)
      }
      if (notification.ActionName === 'toast') {
        const toast = notification.Parameters as ToastPushNotification
        switch (toast.severity) {
          case 'error':
            $addToastError(toast.title, toast.message, toast.lifetime)
            break
          case 'info':
            $addToastInfo(toast.title, toast.message, toast.lifetime)
            break
          case 'warning':
            $addToastWarning(toast.title, toast.message, toast.lifetime)
            break
          case 'success':
            $addToastSuccess(toast.title, toast.message, toast.lifetime)
            break
          default:
            break
        }
      }
      return
    }
    notifications.value.push(notification)
  }
  const removeNotification = (notification: PushNotification) => {
    const index = notifications.value.indexOf(notification)
    if (index > -1) {
      notifications.value.splice(index, 1)
    }
  }
  const getNotifications = (moduleName: string): PushNotification[] => {
    return notifications.value.filter((n) => n.Module === moduleName)
  }
  const isSocketConnected = computed(() => {
    return socketConnected.value
  })
  const socketStateChanged = (socketIsConnected: boolean) => {
    socketConnected.value = socketIsConnected
  }
  const setAutoReconnect = (autoReconnect: boolean) => {
    autoReconnectEnabled.value = autoReconnect
  }
  const setSocketError = () => {
    errorCount.value++
  }
  const isAutoReconnectEnabled = computed(() => {
    if (errorCount.value > 10) {
      return false
    }
    return autoReconnectEnabled.value
  })
  const connectSocket = async () => {
    await $WSConnect()
  }
  return {
    addNotification,
    removeNotification,
    getNotifications,
    isSocketConnected,
    socketStateChanged,
    setAutoReconnect,
    connectSocket,
    isAutoReconnectEnabled,
    setSocketError,
  }
})

import { useUserStore } from '@/stores/user'

export default defineNuxtRouteMiddleware((to, from) => {
  // skips middleware on server side -- only helps local dev
  if (process.server) return

  const { isHydrating, payload } = useNuxtApp()
  const userStore = useUserStore()
  userStore.refreshUserDetails()

  // hydrating equates to first run on S3/CF
  if (
    isHydrating &&
    payload.serverRendered &&
    userStore.returnURL &&
    userStore.returnURL !== to.fullPath &&
    to.fullPath !== from.fullPath
  ) {
    return navigateTo(userStore.returnURL)
  }

  if (to.fullPath === '/') {
    userStore.clearReturnURL()
  } else {
    userStore.setReturnURL(to.fullPath)
  }
})

<script setup lang="ts">
useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - The Mailworks` : 'The Mailworks'
  },
})
</script>

<template>
  <div>
    <PrimeToast />
    <NuxtLayout :key="$route.fullPath">
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<style lang="postcss">
html {
  .btn {
    &.btn-primary {
      &:disabled {
        @apply bg-primary-faded text-primary-content cursor-not-allowed pointer-events-auto;
      }
    }

    &.btn-twitter {
      @apply bg-black text-white;
    }

    &.btn-linkedin {
      @apply bg-sky-600 text-white;
    }

    &.btn-instagram {
      @apply text-white;

      background: radial-gradient(
          circle farthest-corner at 35% 90%,
          #fec564,
          transparent 50%
        ),
        radial-gradient(
          circle farthest-corner at 0 140%,
          #fec564,
          transparent 50%
        ),
        radial-gradient(
          ellipse farthest-corner at 0 -25%,
          #5258cf,
          transparent 50%
        ),
        radial-gradient(
          ellipse farthest-corner at 20% -50%,
          #5258cf,
          transparent 50%
        ),
        radial-gradient(
          ellipse farthest-corner at 100% 0,
          #893dc2,
          transparent 50%
        ),
        radial-gradient(
          ellipse farthest-corner at 60% -20%,
          #893dc2,
          transparent 50%
        ),
        radial-gradient(
          ellipse farthest-corner at 100% 100%,
          #d9317a,
          transparent
        ),
        linear-gradient(
          #6559ca,
          #bc318f 30%,
          #e33f5f 50%,
          #f77638 70%,
          #fec66d 100%
        );
    }
  }

  .dropdown-dark {
    .menu {
      @apply z-[1] py-2 px-0 shadow bg-accent rounded-b min-w-max max-w-xs;

      hr {
        @apply w-3/4 mx-auto;
      }

      li {
        @apply items-center text-white;

        button,
        a {
          @apply flex items-center justify-center w-full text-base text-white hover:text-secondary hover:bg-accent-dark px-5 py-3;

          .menu-subtext {
            @apply inline-block p-0 text-neutral hover:text-neutral;
          }
        }
      }
    }
  }

  .half-form {
    main {
      a,
      button {
        &:not(.btn) {
          @apply link link-secondary;
        }
      }
    }
  }

  main {
    a {
      &:not(.btn, .setcolor) {
        @apply link link-primary link-hover;
      }
    }

    .menu {
      a {
        &:hover {
          @apply no-underline;
        }
      }
    }

    .table {
      thead {
        tr {
          th {
            @apply font-normal text-base;
          }
        }
      }

      tbody {
        tr {
          td {
            @apply text-base;
          }

          &.active,
          .active:nth-child(even) {
            @apply bg-base-200;
          }
        }
      }

      &.table-zebra {
        tbody {
          tr:nth-child(even) {
            @apply bg-base-200;
          }
        }
      }
    }

    .btn {
      &-static {
        @apply w-56;
      }

      &.btn-neutral {
        @apply text-white;
      }

      &-md {
        height: 2.5rem;
        min-height: 2.5rem;
      }
    }

    .sidebar {
      .menu {
        a,
        button {
          @apply text-neutral py-3;

          &.router-link-active,
          &.active,
          &:hover,
          &:active,
          &:focus {
            @apply bg-accent text-white border-0;
          }
        }
      }
    }
  }
}
</style>

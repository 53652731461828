import validate from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/node_modules/.pnpm/nuxt@3.9.0_@types+node@18.19.4_eslint@8.56.0_typescript@5.3.3_vite@5.2.13/node_modules/nuxt/dist/pages/runtime/validate.js";
import refresh_45global from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/middleware/refresh.global.ts";
import websocket_45global from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/middleware/websocket.global.ts";
import manifest_45route_45rule from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/node_modules/.pnpm/nuxt@3.9.0_@types+node@18.19.4_eslint@8.56.0_typescript@5.3.3_vite@5.2.13/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  refresh_45global,
  websocket_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/middleware/auth.ts"),
  noauth: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/middleware/noauth.ts"),
  permissions: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/middleware/permissions.ts")
}
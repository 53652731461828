import { setActivePinia, createPinia } from 'pinia'
import { useUserStore } from '@/stores/user'
import { useCompanyStore } from '@/stores/companies'
import admin from '@/models/roles/client/admin'
import approver from '@/models/roles/client/approver'
import billing from '@/models/roles/client/billing'
import marketer from '@/models/roles/client/marketer'
import accounting from '@/models/roles/internal/accounting'
import accountManager from '@/models/roles/internal/account_manager'
import salesManager from '@/models/roles/internal/sales_manager'
import managingDirector from '@/models/roles/internal/managing_director'
import production from '@/models/roles/internal/production'
import productManagement from '@/models/roles/internal/product_management'
import systemAdmin from '@/models/roles/internal/system_admin'
import vp from '@/models/roles/internal/vp'
import type { actions, domains, rolesClient } from '@/models/rbac'

export default defineNuxtPlugin(() => {
  setActivePinia(createPinia())
  const userStore = useUserStore()
  const companiesStore = useCompanyStore()

  const getFirstCompanyId = () => {
    const claims = userStore.getTokenClaims

    if (claims?.[0]) {
      return claims[0].company_id
    }

    return 0
  }

  const getAccessLevel = () => {
    const claims = userStore.getTokenClaims

    if (claims) {
      const route = useRouter().currentRoute.value
      // skipcq: JS-0323
      const filteredClaims = claims.filter((item: any) => {
        return item.company_id === parseInt(route.params.companyId as string)
      })

      return filteredClaims[0].role
    }

    return 'none'
  }

  const getCompanyRole = (companyId: number) => {
    if (companiesStore.clientViewCompany?.Id) {
      return 'admin'
    }

    const claims = userStore.getTokenClaims

    if (claims) {
      // skipcq: JS-0323
      const filteredClaims = claims.filter((item: any) => {
        return item.company_id === companyId
      })

      if (filteredClaims?.[0] && filteredClaims[0].role) {
        return filteredClaims[0].role as rolesClient
      }
    }

    return undefined
  }

  const getSystemRole = () => {
    let systemRole = userStore.getSystemRole

    if (systemRole) {
      if (
        systemRole !== 'client_user' &&
        companiesStore.clientViewCompany?.Id
      ) {
        systemRole = 'client_user'
      }

      return systemRole
    }

    return 'client_user'
  }

  const getTrueSystemRole = () => {
    return userStore.getSystemRole
  }

  const inAccessGroup = (groups: string[]) => {
    return groups.includes(getAccessLevel())
  }

  const hasSystemRole = (groups: string[]) => {
    return groups.includes(getSystemRole())
  }

  const userCan = (
    action: actions,
    domain: domains,
    companyId?: number,
  ): boolean => {
    const systemRole = getSystemRole()

    if (companiesStore.clientViewCompany?.Id) {
      companyId = companiesStore.clientViewCompany.Id
    }

    if (systemRole) {
      if (systemRole === 'client_user' && companyId) {
        const clientRole = getCompanyRole(companyId)
        if (clientRole) {
          switch (clientRole) {
            case 'admin':
              return admin.can(action, domain)
            case 'approver':
              return approver.can(action, domain)
            case 'billing':
              return billing.can(action, domain)
            case 'marketer':
              return marketer.can(action, domain)
            default:
              break
          }
        }
      } else {
        switch (systemRole) {
          case 'mw_system_admin':
            return systemAdmin.can(action, domain)
          case 'mw_vp':
            return vp.can(action, domain)
          case 'mw_account_manager':
            return accountManager.can(action, domain)
          case 'mw_sales_manager':
            return salesManager.can(action, domain)
          case 'mw_accounting':
            return accounting.can(action, domain)
          case 'mw_managing_director':
            return managingDirector.can(action, domain)
          case 'mw_production':
            return production.can(action, domain)
          case 'mw_product_management':
            return productManagement.can(action, domain)
          default:
            break
        }
      }
    }

    return false
  }

  const userCanAny = (
    actions: actions[],
    domain: domains,
    companyId?: number,
  ): boolean => {
    const systemRole = getSystemRole()
    let can = false

    if (companiesStore.clientViewCompany?.Id) {
      companyId = companiesStore.clientViewCompany.Id
    }

    if (systemRole) {
      if (systemRole === 'client_user' && companyId) {
        const clientRole = getCompanyRole(companyId)
        if (clientRole) {
          actions.forEach((action) => {
            switch (clientRole) {
              case 'admin':
                if (admin.can(action, domain)) {
                  can = true
                }
                break
              case 'approver':
                if (approver.can(action, domain)) {
                  can = true
                }
                break
              case 'billing':
                if (billing.can(action, domain)) {
                  can = true
                }
                break
              case 'marketer':
                if (marketer.can(action, domain)) {
                  can = true
                }
                break
              default:
                break
            }
          })
        }
      } else {
        actions.forEach((action) => {
          switch (systemRole) {
            case 'mw_system_admin':
              if (systemAdmin.can(action, domain)) {
                can = true
              }
              break
            case 'mw_vp':
              if (vp.can(action, domain)) {
                can = true
              }
              break
            case 'mw_account_manager':
              if (accountManager.can(action, domain)) {
                can = true
              }
              break
            case 'mw_sales_manager':
              if (salesManager.can(action, domain)) {
                can = true
              }
              break
            case 'mw_accounting':
              if (accounting.can(action, domain)) {
                can = true
              }
              break
            case 'mw_managing_director':
              if (managingDirector.can(action, domain)) {
                can = true
              }
              break
            case 'mw_production':
              if (production.can(action, domain)) {
                can = true
              }
              break
            case 'mw_product_management':
              if (productManagement.can(action, domain)) {
                can = true
              }
              break
            default:
              break
          }
        })
      }
    }

    return can
  }

  return {
    name: 'RBAC',
    parallel: true,
    provide: {
      getFirstCompanyId,
      getAccessLevel,
      getCompanyRole,
      getSystemRole,
      getTrueSystemRole,
      inAccessGroup,
      hasSystemRole,
      userCan,
      userCanAny,
    },
  }
})
